import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 80,
		overflowY: 'auto'
	},
	logo: {
		height: 60
	},
	title: {
		fontFamily: 'Helvetica',
		fontSize: 28,
		color: Colors.white,
		margin: '12px 0px'
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: 12,
		paddingRight: 50
	},

	errorTitle: {
		fontFamily: 'Helvetica',
		fontSize: 40,
		marginBottom: 20
	},
}
