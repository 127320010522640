import Colors from "../../../theme/colors/colors";

export default {
	titleContainer: {
		textAlign: "center",
		width: '100%'
	},
	titleText: {
		fontFamily: 'Helvetica',
		fontSize: 18,
		color: Colors.text,
		textAlign: "center",
	},
	titleDoctorInfo: {
		fontSize: 18,
		color: Colors.text,
		margin: '8px 0'
	},
	questionContainer: {
		backgroundColor: 'white',
		border: '1px solid #DFDFDF',
		padding: 8
	},
	questionText: {
		fontWeight: 800,
		color: Colors.text,
		fontSize: 20,
		marginTop: 0,
		marginBottom: 12
	},
	cardsContainer: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: 'center',
		paddingBottom: 60
	},
	buttonContainer: {
		position: "absolute",
		bottom: 8,
		left: 0,
		display: "flex",
		justifyContent: "center",
		width: '100%'
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
		gap: 16,
		color: Colors.primary
	},
	cardImage: {
		width: 90
	}
};
