import React from "react";

import "./styles.css";

const VerdadOMitoCard = ({
							 verdad = false, text, onCardClick = () => {
	},
							 isDefaultStatus = true, resultText = "",
							 shouldSelect = false, selected = false, addBorder = false
						 }) => {
	let classes = "container-vod";
	if (verdad) classes += " verdad";
	if (shouldSelect && selected) classes += " selected";
	if (shouldSelect && !selected) classes += " not-selected";
	if (addBorder) classes += " add-border";

	return (
		<div
			className={classes}
			onClick={onCardClick}
		>
			<p className={`card-text-vod ${!verdad && !isDefaultStatus ? "--text-disabled" : ""}`}></p>
			{resultText && <p className={`card-result-vod`}>{resultText}</p>}
		</div>
	)
};

export default VerdadOMitoCard;
