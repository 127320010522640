export default {
	bodyWidth: {
		maxWidth: 1250,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		paddingTop: 32,
		flex: 1,
		height: '100%',
		boxSizing: 'border-box',
		overflow: 'hidden',
		marginBottom: 44
	},
	footerLegal: {
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	footerWrapper: {
		position: "absolute",
		bottom: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 44,
	},
	footerInternal: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		margin: "0 20px"
	},
	footerText: {
		fontSize: 18,
	},
	modalContent: {
		width: 200,
		paddingTop: 10,
		display: 'flex',
		marginRight: 50,
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}
