import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 100
	},
	logo: {
		width: '90%'
	},
	sloganImage: {
		width: '0'
	},
	title: {
		fontFamily: "Helvetica",
		fontSize: 28,
		color: Colors.white,
		margin: '20px 0px'
	}
}
