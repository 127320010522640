export default {
	mainContainer: {
		width: '100%',
		paddingBottom: 30,
		alignSelf: 'center',
		display: 'flex',
		gap: 20,
		flexDirection: 'column'
	},
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
		gap: 14
	}
};
