import Colors from "../../theme/colors/colors";

export default {
	container: {
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingTop: 120,
		overflowY: 'auto'
	},
	topContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	logoImage: {
		width: '80%',
		maxWidth: 720
	},
	sloganImage: {
		maxWidth: 400,
		width: '90%'
	},
	landingText: {
		fontFamily: 'Helvetica',
		fontSize: 22,
		color: Colors.white,
		marginBottom: 24,
		padding: 8,
		textAlign: 'center'
	}
};
