import React from 'react';

import Colors from "../../../theme/colors/colors";
import styles from "./styles";

import Loader from "../loader/Loader";

const Button = ({
					title, onClick = () => {
	}, disable = false, loading = false, theme = "primary"
				}) => {

	let style = {...styles.container}

	if (disable) {
		style = {
			...style,
			backgroundColor: Colors.disabledAccent,
			cursor: 'auto'
		}
	}

	if (theme === "secondary") {
		style = {
			...style,
			backgroundColor: Colors.primary
		}
	}

	let titleStyle = {...styles.title}

	if (theme === "secondary") {
			titleStyle = {
				...titleStyle,
				color: Colors.white
			}
	}

	if (disable) {
		titleStyle = {
			...titleStyle,
			color: Colors.silverSand
		}
	}

	let loaderColor = 'primary';
	if (theme === "secondary") {
		loaderColor = 'white';
	}

	return (
		<button type={"submit"} style={style} onClick={() => !disable && onClick()}>
			{loading
				? <Loader color={loaderColor}/>
				: <p style={titleStyle}>{title}</p>
			}
		</button>
	)
};

export default Button;
