import Colors from "../../../theme/colors/colors";

export default {
	container: {
		border: 'none',
		minWidth: 200,
		height: 52,
		borderRadius: 26,
		backgroundColor: Colors.accent,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		width: '100%',
		paddingLeft: 16,
		paddingRight: 16,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		color: Colors.primary
	}
}
