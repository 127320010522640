import Colors from "../../../theme/colors/colors";

export default {
	container: {
		paddingTop: 68,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		overflowY: 'auto'
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	logo: {
		height: 240
	},
	title: {
		fontFamily: "Helvetica",
		fontSize: 28,
		color: Colors.white,
		marginTop: 56,
		marginBottom: 12
	},
	cardsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 16,
		minHeight: 278
	},
	singleCardWrapper: {
		margin: 32
	}
};
