export default {
	white: "#FFFFFF",
	primary: "#10335A",
	primaryLight: "#204269",
	accent: "#FFB234",
	completed: "#76D167",
	text: "#525252",
	davyGray: "#575757",
	disabledAccent: "rgba(0,97,161,0.4)",
	silverSand: "#C4C4C4",
	green: 'linear-gradient(180deg, rgba(182, 241, 191, 0.2) 0%, rgba(181, 248, 231, 0.2) 100%), #FFFFFF',
	greenBorder: "#76D167",
	greenText: '#498360',
	red: '#ae2f2f',
	affirmationsBorder: '#C9CAC8'
}
